import React from 'react';
import { Loading } from 'sw-ui';
import { observer } from 'mobx-react-lite';

import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import { rootService } from '../../../../v2/core/business';

import SelectAnalytics from '../../../trip/components/selectAnalytics';

import styles from './styles/index.module.css';

const CartAnalyticsRow = observer(({
  analytics,
  cartId,
  onSelect,
  selectedValues,
}) => {
  const arrOfAnalyticsSlices = lodashReplaces.chunk(analytics, 3);
  const { loading, analyticsData } = rootService.services.analytics.customAnalytics;

  const cartAnalyticsData = analyticsData.filter(({ Id }) => selectedValues.includes(Id));

  const rows = arrOfAnalyticsSlices.map((arr, i) => {
    const analyticsSlicesHtml = arr.map((item) => {
      const value = cartAnalyticsData?.filter(({ UserAnalyticsId }) => UserAnalyticsId === item.Id)[0] || {};

      return (
        <div className={ styles.analytic }>
          <SelectAnalytics
            key={ item.Id }
            analytics={ item }
            onSelect={ ({ id }) => onSelect({ analytics: item, analyticsValueId: id }) }
            value={ value }
          />
        </div>
      );
    });

    return (
      <div key={ i } className={ cartId ? styles.cart_row : '' }>
        { analyticsSlicesHtml }
      </div>
    );
  });

  if (loading) {
    return (
      <Loading size='small' />
    );
  }

  return (
    <div>
      { rows }
    </div>
  );
});

export default CartAnalyticsRow;
